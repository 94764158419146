<!--  -->
<template>
  <div class="orderdetail">
    <div class="orderdetail-top card">
      <p class="orderdetail-p" @click="$router.push('/home')">订单详情</p>
      <div class="orderdetail-color">
        <ul>
          <li>
            <span>订单号：</span>
            <p>{{detailsList.order_no}}</p>
          </li>
          <li>
            <span>订单状态：</span>
            <p>{{detailsList.status_name}}</p>
          </li>
          <li>
            <span>下单时间：</span>
            <p>{{detailsList.created_at}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="orderdetail-center card">
      <p class="center-p">订单流程</p>
      <div class="order-top">
        <ul>
          <li>
            <span class="li-span">1</span>
            <span class="lispan spanto">订单关闭</span>
          </li>
          <li>
            <span class="li-span" :class="detailsList.status == 2?'activeNew' :''">2</span>
            <span class="lispan" :class="detailsList.status == 2?'activeNew1': ''">租赁中</span>
          </li>
          <li>
            <span class="li-span  " :class="detailsList.status != 3 && detailsList.status != 5 &&detailsList.status!=4 ?'activeOld ': 'activeNew'">3</span>
            <span class="lispan" :class="detailsList.status != 3 && detailsList.status != 5&&detailsList.status!=4?'': 'activeNew1'">交易完成</span>
          </li>
        </ul>
        <div class="line"></div>
      </div>
    </div>
    <div class="orderdetail-contaner orderdetail-center card">
      <p class="center-p">订单流程</p>
      <div class="bottom-price">
        <div>
          <span>订单号：{{detailsList.order_no}}</span>
          <span class="price-span">创建时间：{{detailsList.created_at}}</span>
        </div>
        <div>
          <span>租赁时间：{{detailsList.created_at}} 至 {{detailsList.end_time}}</span>
        </div>
      </div>
      <div class="bottom-boder">
        <div class="bottom-info">
          <div class="info-left">商品信息</div>
          <div class="info-right">
            <div class="info-left">订单金额</div>
            <div class="info-left">订单押金</div>
            <div class="info-left">优惠</div>
            <div class="info-left">错误赔付</div>
            <div class="info-left">订单总额</div>
          </div>
        </div>
        <div class="bottom-box">
          <div class="bottom-img">
            <img :src= goods_info.game_img alt="" />
            <div>
              <p>
                {{goods_info.goods_title ||goods_info.pn}}
              </p>
              <span class="comColor"> {{goods_info.game_all_name||`${goods_info.game_name}-${goods_info.game_server_name}-${goods_info.game_zone_name}`}} </span>
            </div>
          </div>
          <div class="bottom-txt">¥{{goods_info.hour_lease||goods_info.pmoney}}</div>
          <div class="bottom-txt">¥{{goods_info.foregift||goods_info.bzmoney}}</div>
          <div class="bottom-txt">¥0.00</div>
          <div class="bottom-txt">¥0.00</div>
          <div class="bottom-txt">¥{{detailsList.pay_amount}}</div>
        </div>
      </div>
    </div>
    <div class="orderdetail-bottom orderdetail-center card">
      <p class="center-p">商品信息</p>
      <div class="title">
        {{goods_info.goods_title||goods_info.youxi}}
      </div>
      <div class="backgroundColor" v-if="!game_login_mode&&is_over">
         <div class="pw">
            <div class="color-btn">
              <span>登录码：</span>
              <p>{{detailsList.pay_no}}</p>
          </div>
          <button type="button" class="buttons"
                          v-clipboard:copy="detailsList.pay_no"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError">复制</button>
         </div>
      </div>
        <div class="backgroundColor" v-if="game_login_mode">
          <div class="pw"><div class="color-btn">
              <span>帐号:</span>
              <p>{{pay_result.game_account}}</p>
          </div>
          <button type="button" class="buttons"
                          v-clipboard:copy="pay_result.game_account"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError">复制</button>
          </div>
           <div class="pw"><div class="color-btn">
              <span>密码:</span>
              <p>{{pay_result.game_pwd}}</p>
          </div>
          <button type="button" class="buttons"
                          v-clipboard:copy="pay_result.game_pwd"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError">复制</button>
          </div>
      </div>
      <div class="info-ul">
        <ul>
          <li>业务类型：{{goods_info.server_name||goods_info.dw}}</li>
          <li>游戏名称：{{goods_info.big_game_name||goods_info.game_name}}</li>
          <li>游戏区服：{{goods_info.game_name||goods_info.game_server_name}}</li>
          <li>角色名： {{goods_info.role_name||goods_info.jsm}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "appeal",
  data() {
    return {
      goods_info:[],
      detailsList: {},
      pay_result:{},
      is_over:true,
      game_login_mode:false
    };
  },
  created() {
    this.getDetailsList()
    },
  methods: {
    // 获取信息
    getDetailsList () {
      let order_no = this.$route.params.Id
      let user_id = this.$store.state.user_id
      this.$get('api/order/detail', { user_id, order_no}).then(res => {
        if (res.code == 0) {
          console.log(res,'orderde');
          this.detailsList = res.ret
          this.pay_result = this.detailsList.pay_result
          if(res.ret.new_channel==1){
            this.goods_info = res.ret.goods_info
          }else{
            this.goods_info = res.ret.goods_info.data.list[0]
          }
          

         if(this.detailsList.pay_result.gameLoginType=='1'){
            this.game_login_mode=true;
          }else{
            this.game_login_mode=false;
          }
          if(this.detailsList.status>2){
            this.game_login_mode=false;
            this.is_over=false;
          }
        }
      })
    },
    onCopy: function (e) {
      this.$message.success('复制成功');
       //alert('You just copied: ' + e.text)
    },
    onError: function (e) {
      this.$message.error('复制失败');
    },
  },
};
</script>

<style lang='scss' scoped >
.orderdetail {
  width: 968px;
  float: right;
  .orderdetail-top {
    padding: 30px 0 0 38px;
    .orderdetail-p {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      padding-left: 15px;
      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 0px;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
      }
    }
    .orderdetail-color {
      width: 894px;
      height: 80px;
      background: #f9fafb;
      border-radius: 4px;
      margin: 39px 0 27px;
      display: flex;
      justify-content: center;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          span {
            color: #83899d;
          }
          p {
            color: #010101;
          }
        }
      }
    }
  }
  .orderdetail-center {
    padding: 30px 38px;
    .center-p {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }
    .order-top {
      position: relative;
      height: 142px;
      width: 70%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 355px;
        top: 41%;
        right: 50%;
        transform: translateX(50%);
        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #83899d;
          // margin-left: 110px;
          .li-span {
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 50%;
            background: #3c7efe;
            display: block;
            text-align: center;
            color: #fff;
            margin-bottom: 15px;
          }
          .activeOld {
            background: #9ebefe;
          }
          .activeNew {
            border: 4px solid #ccddff;
            width: 25px;
            height: 25px;
          }
          .activeNew1 {
            color: #000;
          }
          .lispan {
            font-size: 12px;
            position: relative;
          }
          .spanto {
            &::before {
              content: "订单超时未支付，系统自动取消";
              position: absolute;
              bottom: -30px;
              left: 50%;
              transform: translateX(-50%);
              width: 175px;
              font-size: 12px;
              color: #848a9e;
            }
          }
        }
      }
      .line {
        width: 300px;
        margin: 0 auto;
        border-bottom: 1px dashed #9dbefe;
      }
    }
  }
  .orderdetail-contaner {
    .bottom-price {
      width: 890px;
      height: 38px;
      background: #f6f7fa;
      border-radius: 4px 4px 0px 0px;
      font-size: 12px;
      color: #83899d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      margin-top: 38px;
      .price-span {
        margin-left: 50px;
      }
    }
    .bottom-boder {
      border: 1px solid #f6f7fa;
      .bottom-info {
        width: 100%;
        height: 50px;
        border-radius: 12px 12px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        .info-left {
          width: 60px;
          font-size: 14px;
          color: #848a9e;
        }
        .info-right {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 500px;
        }
      }
      .bottom-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 20px 0;
        width: 894px;
        .bottom-img {
          display: flex;
          align-items: center;
          width: 310px;
          justify-content: space-between;
          img {
            width: 80px;
            height: 80px;
            border-radius: 12px;
            }
          p {
            width: 210px;
            height: 60px;
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .bottom-txt {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #848a9e;
        }
      }
    }
  }
  .orderdetail-bottom {
    .title {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #848a9e;
      margin: 26px 0 36px;
    }
    .backgroundColor {
      width: 894px;
      background: #F6F7FA;
      border-radius: 4px;
      margin-bottom: 50px;
      padding:5px 16px;
      .pw{
        display: flex;
      padding:5px;
      align-items: center;
     
      }
      .buttons{
        width: 60px;
        height: 32px;
        border-radius: 12px;
        text-align: center;
        line-height: 10px;
        background-color: #3c7efe;
        color: #fff;
      }
      .color-btn{
          font-size: 16px;
          color: #000;
          display: flex;
          align-items: center;
          margin-right: 38px;
          width: 300px;
          span{
              // width: 100px;
              color: #848A9E;
          }
      }
    }
    .info-ul {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 33.3%;
          height: 40px;
          font-size: 14px;
          color: #848a9e;
        }
      }
    }
  }
}

//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
</style>