<template>
  <div class="messagedetails">
    <div class="btt">
      <div class="btts"><span></span>消息详情</div>
    </div>
    <div class="xx_top">
      <h4>{{messageList.title}}</h4>
      <p class="clearfix">
        发件人:{{messageList.type_name}} 时间:{{messageList.created_at}} <span v-if="messageList.type==1" @click="delMsg">删除</span>
      </p>
    </div>
    <div class="xx_content" v-html="messageList.content">
     
    </div>
    <div class="xx_bom">
      <button @click="getOtherDetail(messageList.last)">上一篇</button>
      <button @click="getOtherDetail(messageList.next)">下一篇</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		news_id:0,
		messageList: []
    };
  },
  created() {
	this.getMesDeilter()
  },
  methods: {
	getMesDeilter () {
		this.news_id = this.$route.params.id
		this.$get('api/msgsys/detail', {id:this.news_id}).then(res => {
			if (res.code == 0) {
				this.messageList=res.ret
			}
		})
    },
	getOtherDetail(id){
		if(!id){
			this.$message.error('暂无数据');
			return;
		}
		this.$router.push({
			path:`${id}`
		})
		this.id
		this.getMesDeilter()
	},
	delMsg(){
		this.$post('api/msgsys/del', {id:this.news_id}).then(res => {
			
		})
	},
  }
};
</script>

<style lang="scss" scoped>
.messagedetails {
  background-color: #fff;
  border-radius: 12px;
  .xx_top {
    border-bottom: 1px solid #f6f7fa;
    h4 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 700;
      text-align: center;
    }
    p {
      width: 100%;
      height: 20px;
      font-size: 12px;
      color: #a1a6b7;
      text-align: center;
      margin-top: 10px 0 20px 0;
      span {
        float: right;
        position: relative;
        display: inline-block;
        width: 80px;
        height: 100%;
        margin-right: 10px;
      }
      span::after {
        position: absolute;
        top: 0;
        left: 5px;
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: url("../../assets/member/sc.png") no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
  .xx_content {
    margin-top: 30px;
    width: 100%;
    padding: 0 36px;
    box-sizing: border-box;
    color: #83899d;
    font-size: 13px;
    p {
      line-height: 30px;
    }
    div {
      width: 100%;
      line-height: 30px;
      text-align: right;
    }
    .xx_yw {
      margin-top: 100px;
    }
  }
  .xx_bom {
    padding: 45px 0;
    width: 100%;
    text-align: center;
    button {
      width: 80px;
      height: 30px;
      border: 1px solid #e2e4ea;
      border-radius: 16px;
      color: #a1a6b7;
      font-size: 14px;
      background-color: #fff;
      margin-left: 12px;
     
    }
  }
}
//共用样式
.btt {
  width: 100%;
  height: 65px;
  padding: 25px 0 0 35px;
  .btts {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    span {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #3c7efe;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
}
</style>