<template>
  <div class="browse">
    <div class="btt">
      <div class="btts"><span></span>浏览记录</div>
    </div>
    <div class="c_search clearfix" v-if="false">
      <input type="text" placeholder="游戏名/订单号/标题" />
      <div>搜索</div>
    </div>
    <div class="spxx">
      <span>商品信息</span>
      <span>价格</span>
      <span>操作</span>
    </div>
    <ul class="c_gamelist" v-if="collect != []">
      <li class="clearfix" v-for="item in collect" :key="item.id">
        <img :src="item.goods_info.game_img" alt="" />
        <div class="c_gamelf">
          <h5>
            {{ item.goods_info.detail.goods_title }}
          </h5>
          <p>{{ item.goods_info.detail.game_all_name }}</p>
          <p>商品热度：{{ item.goods_info.detail.buy_count }}</p>
        </div>
        <div class="c_gamezj">
          <p>
            租金<span>¥{{ item.goods_info.detail.hour_lease }}</span
            >/小时
          </p>
          <p>
            押金<span>¥{{ item.goods_info.detail.foregift }}</span>
          </p>
          <p>
            <span>{{ item.goods_info.detail.short_lease }}</span
            >小时起租
          </p>
        </div>
        <div class="c_gamerg">
          <div class="abt" @click="getDescribe(item.goods_info.detail.id)">
            立即租赁
          </div>
          <div @click="delAll(item.id)">删除记录</div>
        </div>
      </li>
    </ul>
    <ul class="c_gamelist" v-else>
      <li class="ul_li">您还没有浏览记录!请赶快去看看吧!</li>
    </ul>
    <div class="fy">
      <span @click="subclick"></span>
      <span>{{ page }}</span>
      <span @click="flag && addclick()"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        page:1,
        page_size:5,
        count:null,
        collect:[],
        collects:[],
        flag:true,
        pageNam:null
    };
  },
  created(){
    this.getHist()
  },
  methods: {
    subclick() {
      if (this.page > 1) {
        this.page--;
        this.getHist()
      } else {
        this.page = 1;
      }
      
    },
    addclick() {
      if(this.page <this.pageNam){
        this.page++;
        this.getHist()
      }else{
        this.flag =false
      }
     
    },

    getHist() {
      let user_id = this.$store.state.user_id;
      this.$get("api/history/list", {
        user_id,
        page: this.page,
        page_size:this.page_size
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          if(this.page == 1&&res.ret != []){
            this.count =res.ret[0].count
            this.pageNam =Math.ceil(res.ret[0].count/this.page_size)
          }
            this.collect = res.ret;
        }
      });
    },
    getDescribe(id) {
      console.log(id);
      this.$router.push({
        path: `/order/${id}`,
      });
    },
     delAll(index){
     var that =this;
     var checkid = []
     checkid.push(index)
     console.log(checkid);
      that.$post('api/history/del', {
         history_id:checkid
      }).then(res => {
        if (res.code == 0) {
          that.$message.success('删除成功');
          // if(this.collect.length == 0){
          //   this.page--
          //   console.log("本页没了");
          //   that.getHist()
          // }else{
            that.getHist()
          //}
          
        }
      })
   }
  },
};
</script>

<style lang="scss" scoped>
.browse{
  background-color: #fff;
  border-radius: 12px;
  padding-left: 35px;
  box-sizing: border-box;
  .c_search {
    width: 100%;
    height: 36px;
    margin-top: 15px;
    input {
      float: left;
      width: 435px;
      height: 100%;
      outline-style: none;
      font-size: 13px;
      font-family: Microsoft YaHei;
      color: #a2a6b8;
      padding-left: 15px;
      border: 1px solid #c4c8d4;
      border-radius: 4px;
    }
    div {
      float: left;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      width: 86px;
      height: 36px;
      background: #3c7efe;
      border-radius: 4px;
      color: #fff;
      margin-left: 17px;
    }
  }
  .spxx {
    width: 894px;
    height: 42px;
    margin-top: 20px;
    background: #e8e9ec;
    border-radius: 4px;
    font-size: 12px;
    line-height: 42px;
    color: #848a9e;
    span {
      float: left;
    }
    span:nth-child(1) {
      margin-left: 18px;
    }
    span:nth-child(2) {
      margin-left: 570px;
    }
    span:nth-child(3) {
      margin-left: 155px;
    }
  }
  .c_gamelist {
    margin-top: 10px;
    width: 894px;
    // height: 235px;
    border: 1px solid #e8e9ef;
    border-radius: 4px;
    box-sizing: border-box;
    color: #83899d;
     .ul_li{
        height: 240px;
      text-align: center;
      line-height: 240px;
      color: #000;
      font-weight:bold;
      font-size: 24px;
    }
    li {
      width: 100%;
      height: 116px;
      padding: 18px 15px;
      box-sizing: border-box;
      img {
        height: 100%;
        width: 79px;
        border-radius: 12px;
        float: left;
      }
      .c_gamelf {
        width: 410px;
        padding-left: 15px;
        p {
          color: #848a9e;
          font-size: 12px;
        }
        h5 {
          font-size: 14px;
          font-weight: 700;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }
      }
      .c_gamezj {
        width: 100px;
        margin-left: 95px;
        text-align: center;
        p span {
          font-weight: 700;
        }
      }
      .c_gamerg {
        float: right;
        width: 88px;
        .abt {
          background: #3c7efe;
          border-radius: 15px;
          color: #fff;
          cursor: pointer;
        }
        div {
          width: 100%;
          height: 30px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
    li:not(:last-child) {
      border-bottom: 1px solid #f6f7fa;
    }
    li > div {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding: 8px 0;
      box-sizing: border-box;
      float: left;
    }
  }
  .fy {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 86px;
    padding-right: 38px;
    box-sizing: border-box;
    cursor: pointer;
    span {
      display: inline-block;
      width: 32px;
      height: 32px;
    }
    span:nth-child(1) {
      background: url("../../assets/member/zjt1.png") no-repeat center center;
      background-size: cover;
      margin-right: 8px;
    }
    span:nth-child(2) {
      background-color: #e7e9ec;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
    }
    span:nth-child(3) {
      background: url("../../assets/member/yjt1.png") no-repeat center center;
      background-size: cover;
      margin-left: 8px;
    }
    span:nth-child(1):hover {
      background: url("../../assets/member/zjt2.png") no-repeat center center;
    }
    span:nth-child(3):hover {
      background: url("../../assets/member/yjt2.png") no-repeat center center;
    }
  }
}
//共用样式
.btt {
  width: 100%;
  height: 65px;

  padding-top: 25px;
  .btts {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    span {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #3c7efe;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
}
</style>