import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import qs from 'qs'
// import 'https://www.yunpian.com/static/official/js/libs/riddler-sdk-0.2.2.js'

import { get, post } from './http/request'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

import '@/style/index.scss'
Vue.prototype.$get = get
Vue.prototype.$post = post

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    if (sessionStorage.getItem('isLogin') == null) {
      sessionStorage.setItem('isLogin', '')
    }
    this.$store.state.user_id = sessionStorage.getItem('isLogin')

    let token = sessionStorage.getItem('token')
    let params = new URLSearchParams(location.search)
    let channel_id = params.get('channel_id') || null
    let yy_token = params.get('yy_token') || null

    let data = { host: location.hostname === 'localhost' ? 'www.2zh.com' : location.hostname }
    get('api/website', data).then((res) => {
      if (res.code == 0) {
        sessionStorage.setItem('ret', JSON.stringify(res.ret))
        document.title = res.ret.title
      }
    })
    if (yy_token && !token) {
      post('api/user/creg', { yy_token: yy_token, channel_id: channel_id }).then((res) => {
        if (res.code == 0) {
          let user_token = res.ret.user_token
          let id = res.ret.id
          sessionStorage.setItem('token', user_token)
          sessionStorage.setItem('isLogin', id)
          sessionStorage.setItem('ext_cash', res.ret.ext_cash)
          sessionStorage.setItem('avatar', res.ret.avatar)
          sessionStorage.setItem('nick_name', res.ret.nick_name)
          window.location.reload()
        }
      })
    }
  }
}).$mount('#app')
