<template>
  <div class="message">
    <div class="msg_top">我的消息</div>
    <div class="msg_nav">
      <div :class="{ bom: isread == 1 }" @click="toread(1)">站内信</div>
      <div :class="{ bom: isread == 2 }" @click="toread(2)" v-if="false">
        未读消息<span>(1)</span>
      </div>
    </div>
    <div class="mas_tab">
      <div class="tab_top public">
        <span><input type="checkbox" class="input-checkbox" v-model="checked" @click="allSelect()"/><em>全选</em></span>
        <span>主题</span>
        <span>发件人</span>
        <span>日期</span>
      </div>

      <div class="public slid" :class="{ blue: checkeMedo.indexOf(item.id) != -1}" v-for="item in list" :key="item.id">
        <span><input type="checkbox" class="input-checkbox" v-model="checkeMedo" :value="item.id" /></span>
        <!-- <router-link :to="{name: 'messagedetails'}" tag="span" >{{item.title}}</router-link> -->
        <span @click="toPath(item.id)">{{item.title}}</span>
        <span>{{item.type_name}}</span>
        <span>{{item.created_at}}</span>
      </div>
      <!-- 复选框 yw-->
    </div>
    <div class="fy">
      <span @click="subclick"></span>
      <span>{{ page }}</span>
      <span @click="addclick"></span>
    </div>
    <div class="btn_box">
      <button>标记已读</button>
      <button class="bs" v-if="false">删除</button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      page: 1,
      page_size:8,
      count:null,
      flag:true,
      pageNam:null,
      isread: 1,
      checked:false,
      checkeMedo:[],
      //数据list
      list:[]
    };
  },
  components:{
   
  },
  created() {
    this.getMessage()
  },
  methods: {
    subclick() {
      if (this.page > 1) {
        this.page--;
      } else {
        this.page = 1;
      }
    },
    addclick() {
      if(this.page < this.pageNam){
        this.page++;
        this.getCollect()
      }else{
        this.flag =false
      }
    },
    toread(e) {
      this.isread = e;
    },
    // 全选
    allSelect() {
      if(this.checked) {
        this.checkeMedo = []
      }else{
        this.list.forEach(item => {
          if(this.checkeMedo.indexOf(item.id) == -1){
            this.checkeMedo.push(item.id)
          }
        });
      }
    },
    // 获取消息的接口
     getMessage () {
      this.$get('api/msgsys',{}).then(res => {
        if (res.code === 0) {
          this.list = res.ret
      console.log(res)
            // if(this.page == 1&&this.collect.length != 0){
            if(this.page == 1&&res.ret.length != 0){
            this.count =res.ret[0].count
            this.pageNam =Math.ceil(res.ret[0].count/this.page_size)
          }
        }
      })
    },
    toPath(id) {
      this.$router.push({
        path:`messagedetails/${id}`
      })
    }
  },
  watch: {
    checkeMedo() {
      if(this.checkeMedo.length == 8) {
        this.checked = true
      }else{
        this.checked = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  .msg_top {
    padding: 28px 0 0 38px;
    font-weight: 700;
    font-size: 16px;
  }
  .msg_nav {
    display: flex;
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #f6f7fa;
    div {
      position: relative;
      height: 100%;
      line-height: 48px;
      margin-left: 56px;
      font-size: 14px;
      span {
        color: #fc3737;
      }
    }
    div:hover::after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 3px;
      content: "";
      display: block;
      width: 15px;
      height: 3px;
      background: #3c7efe;
      border-radius: 1.5px;
    }
    .bom {
      font-size: 16px;
      font-weight: 700;
    }
    .bom::after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 3px;
      content: "";
      display: block;
      width: 15px;
      height: 3px;
      background: #3c7efe;
      border-radius: 1.5px;
    }
  }
  .mas_tab {
    width: 896px;
    margin: 25px 36px 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #83899d;
    border: 1px solid #f6f7fa;
    border-radius: 12px;
    .tab_top {
      height: 50px;
      background: #f6f7fa;
      border-radius: 12px 12px 0px 0px;
    }
    .public {
      display: flex;
      padding-left: 29px;
      span {
        line-height: 50px;
        em {
          display: inline-block;
          margin-left: 10px;
        }
      }
      span:nth-child(1) {
        width: 95px;
      }
      span:nth-child(2) {
        width: 445px;
      }
      span:nth-child(3) {
        width: 160px;
      }
      span:nth-child(4) {
        width: 165px;
      }
    }
    .blue {
      color: #fff;
      background-color: #3c7efe;
    }
    .slid:not(:first-child) {
      border-top: 1px solid #f6f7fa;
    }
  }
  //分页按钮样式
  .fy {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 86px;
    padding-right: 38px;
    box-sizing: border-box;
    cursor: pointer;
    span {
      display: inline-block;
      width: 32px;
      height: 32px;
    }
    span:nth-child(1) {
      background: url("../../assets/member/zjt1.png") no-repeat center center;
      background-size: cover;
      margin-right: 8px;
    }
    span:nth-child(2) {
      background-color: #e7e9ec;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
    }
    span:nth-child(3) {
      background: url("../../assets/member/yjt1.png") no-repeat center center;
      background-size: cover;
      margin-left: 8px;
    }
    span:nth-child(1):hover {
      background: url("../../assets/member/zjt2.png") no-repeat center center;
    }
    span:nth-child(3):hover {
      background: url("../../assets/member/yjt2.png") no-repeat center center;
    }
  }
  .btn_box {
    padding-bottom: 60px;
   
    button {
      text-align: center;
      font-size: 14px;
      height: 32px;
      border-radius: 16px;
      background-color: #fff;
      border: 1px solid #c3c7d4;
      padding: 0 10px;
      color: #83899d;
       cursor: pointer;
    }
    button:nth-child(1) {
      margin-left: 53px;
    }
    button:nth-child(2) {
      margin-left: 12px;
    }
    .bs:hover {
      color: #3c7efe;
      border: 1px solid #3c7efe;
    }
  }
}
</style>