import Vue from 'vue'
import VueRouter from 'vue-router'
import giftbar from '../views/giftbar/giftbar.vue'
import personal from '../views/personal/personal.vue'
import orderDetail from '@/views/orderDetail/orderdetail'

import collection from '../views/collection/collection.vue'
import browse from '../views/browse/browse.vue'
import message from '../views/message/message.vue'
import messagedetails from '../views/messagedetails/messagedetails.vue'

import { post } from '../http/request'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/lobby'
  },
  {
    name: 'home',
    path: '/home',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/home/home.vue')
  },
  {
    name: 'duty',
    path: '/duty',
    meta: {
      title: '免责声明'
    },
    component: () => import('@/views/duty/duty.vue')
  },
  {
    name: 'private',
    path: '/private',
    meta: {
      title: '隐私政策'
    },
    component: () => import('@/views/private/private.vue')
  },
  {
    name: 'service',
    path: '/service',
    meta: {
      title: '服务协议'
    },
    component: () => import('@/views/service/service.vue')
  },
  {
    name: 'about',
    path: '/about',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/about/about.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('@/views/detail/detail'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/novice',
    name: 'novice',
    component: () => import('@/views/novice/Novice '),
    meta: {
      title: '新手引导'
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/protocol/protocol'),
    meta: {
      title: '爱租号仲裁规则'
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('@/views/order/order'),
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/lobby',
    name: 'lobby',
    component: () => import('@/views/lobby/lobby'),
    meta: {
      title: '租号大厅'
    }
  },
  {
    path: '/succese/:id',
    name: 'succese',
    component: () => import('@/views/succese/succese'),
    meta: {
      title: '上号教程'
    }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/download/download'),
    meta: {
      title: '工具下载'
    }
  },
  {
    path: '/center/:id?',
    name: 'center',
    component: () => import('@/views/center/center'),
    meta: {
      title: '帮助中心'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/contact'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      requireAuth: true
    },
    component: () => import('@/views/member/member'),
    children: [
      { path: '', redirect: 'personal' },
      {
        path: 'collection',
        name: 'collection',
        meta: {
          title: '我的收藏',
          fag: 2
        },
        component: collection
      },
      {
        path: 'myOrder',
        name: 'myOrder',
        meta: {
          title: '我的订单',
          fag: 2,
          requireAuth: true
        },
        component: () => import('@/views/myOrder/myOrder')
      },
      {
        path: 'browse',
        name: 'browse',
        meta: {
          title: '浏览记录',
          fag: 2
        },
        component: browse
      },
      {
        path: 'message',
        name: 'message',
        meta: {
          title: '我的消息',
          fag: 5,
          requireAuth: true
        },
        component: message
      },
      {
        path: 'messagedetails/:id',
        name: 'messagedetails',
        meta: {
          title: '消息详情',
          fag: 5
        },
        component: messagedetails
      },
      {
        path: 'personal',
        name: 'personal',
        meta: {
          title: '个人中心',
          fag: 1,
          requireAuth: true
        },
        component: personal
      },
      {
        path: 'giftbar',
        name: 'giftbar',
        meta: {
          title: '我的红包',
          fag: 3
        },
        component: giftbar
      },
      {
        path: 'capital',
        name: 'capital',
        meta: {
          title: '资金明细',
          fag: 3
        },
        component: () => import('@/views/capital/capital')
      },
      {
        path: 'recharge/:id',
        name: 'recharge',
        meta: {
          title: '充值',
          fag: 3
        },
        component: () => import('@/views/recharge/recharge')
      },
      {
        path: 'renzheng',
        name: 'renzheng',
        meta: {
          title: '实名认证',
          fag: 4
        },
        component: () => import('@/views/renzheng/renzheng')
      },
      {
        path: 'password',
        name: 'password',
        meta: {
          title: '找回密码',
          fag: 4
        },
        component: () => import('@/views/password/password')
      },
      {
        path: 'myorder/orderdetail/:Id',
        name: 'orderdetail',
        meta: {
          title: '订单详情',
          fag: 2
        },
        component: orderDetail
      },
      {
        path: 'myorder/appeal/:id',
        name: 'appeal',
        meta: {
          title: '申诉维权',
          fag: 2
        },
        component: () => import('@/views/appeal/appeal')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  //scrollBehavior(to, from, saveTop) { if (saveTop) { return saveTop; } else { return { x: 0, y: 0 } } },
})
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  let userId = sessionStorage.getItem('isLogin')

  // document.title = `${'爱租号-专业安全的游戏帐号出租交易游戏装备体验综合平台-'}${to.meta.title}`

  if (to.query.yy_token) {
    next()
    return
  }

  if (from.query.yy_token) {
    let toQuery = JSON.parse(JSON.stringify(to.query))
    toQuery.yy_token = from.query.yy_token
    toQuery.channel_id = from.query.channel_id
    next({
      path: to.path,
      query: toQuery
    })
  }

  // 判断是否登录
  if (to.meta.requireAuth) {
    if (token && userId) {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
  // 如果本地 存在 token 则 不允许直接跳转到 登录页面
  if (to.fullPath === '/login') {
    if (token) {
      next({
        path: from.fullPath
      })
    } else {
      next()
    }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
