<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
   mounted(){
      // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
      // window.onbeforeunload = function (e) {
      //   var storage = window.localStorage;
      //   storage.clear()
      // }
    }
}
</script>
<style lang="scss">
  @import "assets/css/normalize";
  #app{
  width: 100%;
  margin: 0 auto;
  }
</style>
