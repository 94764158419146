<template>
  <!-- 个人中心 -->
  <div class="personal">
    <div class="mbr_yh">
      <div class="dl">
        <img :src= Prolist.avatar alt="" />
      </div>
      <div class="yhnr">
        <div class="xx">
          <div>{{Prolist.nick_name}}</div>
          <p>可用余额:{{Prolist.ext_cash}}元</p>
        </div>
        <div class="cz" @click="$router.push({path: `recharge/${Prolist.phonenum}`})">
          <img src="@/assets/member/cz.png" alt="" /><span>充值</span>
        </div>
      </div>
    </div>
    <div class="zhxx">
      <div class="btt">
        <div class="btts"><span></span>租号信息</div>
      </div>
      <div class="zh_con">
        <ul class="clearfix">
          <li>
            <p>{{Prolist.count}}</p>
            <span>租号次数</span>
          </li>
          <li>
            <p>{{Prolist.overs}}</p>
            <span>订单完成次数</span>
          </li>
          <li>
            <p>{{Prolist.close}}</p>
            <span>撤单次数</span>
          </li>
          <li>
            <p>{{Prolist.rate}}%</p>
            <span>订单完成率</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="ddxx">
      <div class="btt">
        <div class="btts"><span></span>我的订单</div>
      </div>
      <div v-if="isShow">
        <ul>
          <li v-for="item in shopAllList" :key="item.id">
            <img :src="item.goods_info.game_img ||item.goods_info.data.list[0].game_img" alt="" />
            <div class="ddnr">
              <div class="dd1">
                <p>订单号：{{item.order_no}} <span style="margin-left:9px;">货架号：{{item.goods_id}}</span> </p>
                <p v-if="item.new_channel == 1">{{
                  !item.goods_info.goods_title || item.goods_info.goods_title.substring(0, 39)
                }}</p>
               <p v-if="item.new_channel == 2">{{
                  item.goods_info.data.list[0].pn
                }}</p>
                <p v-if="item.new_channel == 1">角色名：斯里兰卡</p>
                <p v-if="item.new_channel == 2">角色名：{{item.goods_info.data.list[0].jsm}}</p>
              </div>
              <div class="dd2">
                <p>商品价格：{{ item.goods_info.hour_lease ||item.goods_info.data.list[0].pmoney}}元/小时</p>
                <p>下单时间：{{item.game_time}}小时</p>
                <p v-if="item.new_channel == 1">押金：{{item.goods_info.foregift}}</p>
                <p v-if="item.new_channel == 2">押金：{{item.goods_info.data.list[0].bzmoney}}</p>
              </div>
              <div class="dd3">{{item.status_name}}</div>
              <div class="dd4" @click="$router.push({path: `myorder/orderdetail/${item.order_no}`})">订单详情</div>
            </div>
          </li>
        </ul>
        <div class="gdxx" @click="$router.push({name: 'myOrder'})">查看更多<i></i></div>
      </div>
      <div class="txt" v-else>
        <p>{{text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "personal",
  data() {
    return {
      isShow :true,
      // 个人信息
      Prolist:{},
      text:'暂无数据!',
      // 个人部分订单
      shopAllList:[]
    };
  },
  created() {
    this.getProfile()
    this.getShopList()
  },
  mounted() {},
  methods: {
    // 个人中心的信息
     getProfile () {
      this.$get('api/getinfo', {type:1}).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.Prolist = res.ret
        }
      })
    },
    // 

    // 获取部分完成订单完成
     getShopList () {
      var user_id = this.$store.state.user_id
      var that = this;
      that.$get('api/order/list', { user_id, type: 'all', page: 1 }).then(res => {
        if (res.code == 0) {
          if( res.ret.length > 5 ){
            that.shopAllList = res.ret.slice(0,5)
            return
          }
          if(res.ret.length == 0){
            that.isShow = false
            return
          }
          that.shopAllList = res.ret  
          console.log(res,'ord');
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.mbr_yh {
  display: flex;
  width: 100%;
  height: 140px;
  background-color: #fff;
  border-radius: 12px;
  padding: 30px 38px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .dl {
    width: 80px;
    height: 100%;
    img{
      width: 80px;
      height: 80px;
    }
  }
  .yhnr {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    box-sizing: border-box;
    .xx {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 50px;
      div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 700;
      }
      p {
        color: #83899d;
        font-size: 14px;
      }
    }
    .cz {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 48px;
      background: #3c7efe;
      border-radius: 24px;
      box-shadow:0px 1px 10px #3c7efe;
      cursor: pointer;
      img {
        padding-right: 5px;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
.zhxx {
  width: 100%;
  height: 220px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 10px;
  .zh_con {
    width: 100%;
    height: 140px;
    ul {
      li {
        float: left;
        width: 25%;
        height: 140px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        p {
          font-size: 36px;
          font-weight: 700;
        }
        span {
          font-size: 15px;
          color: #83899d;
        }
      }
    }
  }
}
.ddxx {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding-bottom: 30px;
  ul {
    li {
      display: flex;
      align-items: center;
      width: 896px;
      height: 120px;
      margin: 0 37px;
      border-bottom: 1px solid #f6f7fa;
      img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
      }
      .ddnr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        height: 70px;
        padding-left: 15px;
        box-sizing: border-box;
        .dd1 {
          height: 100%;
          width: 416px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          p:nth-child(1) {
            font-size: 14px;
            font-weight: 700;
          }
          p:nth-child(2) {
            font-size: 12px;
          }
          p:nth-child(3) {
            font-size: 12px;
            color: #83899d;
          }
        }
        .dd2 {
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          color: #83899d;
          font-size: 12px;
        }
        .dd3 {
          color: #83899d;
          font-size: 13px;
          width: 54px;
          text-align: center;
        }
        .dd4 {
          width: 88px;
          height: 30px;
          border: 1px solid #c3c7d4;
          border-radius: 15px;
          font-size: 13px;
          text-align: center;
          line-height: 30px;
          color: #83899d;
          cursor: pointer;
        }
        .dd4:hover {
          background: #3c7efe;
          border: 1px solid #3c7efe;
          color: #fff;
          box-shadow: 0px 2px 12px #3c7efe;
;
        }
      }
    }
  }
  .gdxx {
    margin: 30px auto  0;
    width: 110px;
    height: 30px;
    font-size: 13px;
    background: #ffffff;
    border: 1px solid #d9dde4;
    border-radius: 15px;
    color: #83899d;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    i {
      display: inline-block;
      width: 6px;
      height: 8px;
      background: url(../../assets/member/gd.png) no-repeat center center;
      margin-left: 10px;
    }
  }
  .gdxx:hover {
    border: 1px solid #3c7efe;
    color: #3c7efe;
  }
  .gdxx:hover i {
    background: url(../../assets/member/gd2.png) no-repeat center center;
  }

  .txt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    p{
      height: 80px;
      font-size: 30px;
      color: #000;
      font-weight: bold;
      text-align: center;
      line-height: 80px;
    }
  }
}
//共用样式
.btt {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #f6f7fa;
    padding: 25px 0 0 35px;
    .btts {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
}
</style>