import axios from 'axios'
import md5 from '../common/md5'
import config from '../common/config'
// 1. 创建
const instance = axios.create({
  baseURL: config.domain,
  timeout: 5000
})
export function get (url, params = {}, comparar) {
  const paraurl = getSignUrl(comparar, params, url)
  return new Promise((resolve, reject) => {
    instance.get(paraurl, {
      params: {}
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function post (url, params = {}, comparar) {
  const paraurl = getSignUrl(comparar, {}, url)
  return new Promise((resolve, reject) => {
    instance.post(paraurl, params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getuserinfo () {
  try {
    const userinfo = sessionStorage.getItem('isLogin')
    return userinfo
  } catch (e) {
    return []
  }
}
function getuid () {
  const userinfo = getuserinfo()
  if (userinfo) {
    return userinfo
  } else {
    return 0
  }
}
function getData (comparar, getParm) {
  if (comparar && comparar.pt == 3) {
    getParm.user_id = comparar.user_id
    getParm.ver = comparar.ver
    getParm.pt = comparar.pt
  } else {
    getParm.user_id = getuid()
    getParm.ver = config.ver
    getParm.pt = config.pt
  }
  getParm.appid = config.appid
  getParm.timestamp = Date.parse(new Date()) / 1000
  return getParm
}

function getSign (parms) {
  const md5Str = md5.encrypt(parms, config.key)
  return md5.hexMD5(md5Str)
}
function getSignUrl (comparar, ugetParm = {}, tp) {
  const getParm = getData(comparar, ugetParm)
  const signParm = {}
  for (const k in getParm) {
    signParm[k] = getParm[k]
  }
  for (const k in ugetParm) {
    signParm[k] = ugetParm[k]
  }
  getParm.sign = getSign(signParm)
  let url = config.domain + tp + '?'
  for (const k in getParm) {
    url += `${k}=${getParm[k]}&`
  }
  return url
}
// 2.开启拦截器
instance.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token')
  if (token) {
  //   // 添加headers
    config.headers.Authorization = 'bearer' + ' ' + `${token}`
  }
  return config;
}, err => {
  console.log(err)
})
instance.interceptors.response.use(res => {
  // var token = response.headers.authorization;
  // if (token) {
  //   // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
  //   instance.defaults.headers.common['Authorization'] = 'bearer' + ' ' + `${token}`
  // }
  return res.data
}, err => {
  // if (err.response) {
  //   switch (err.response.status) {
  //     // 返回401，清除token信息并跳转到登录页面
  //     case 401:
  //       sessionStorage.removeItem('token')
  //       router.replace({
  //         path: '/login'
  //         // 登录成功后跳入浏览的当前页面
  //         // query: {redirect: router.currentRoute.fullPath}
  //       })
  //   }
  //   // 返回接口返回的错误信息
  //   return Promise.reject(err.response.data)
  // }
  console.log(err)
})
