import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_id:"",
  },
  mutations: {
    // 保存用户信息
    // saveUserInfo(state, msg) {
    //   state.user_id = msg
    // },
    isLogin(state,msg){
      state.user_id = msg
      sessionStorage.setItem("isLogin",msg);
    }
  },
  actions: {
  },
  modules: {
  },
  getters:{

  }
})
