<template>
  <div class="giftbar">
    <div class="btt">
      <div class="btts"><span></span>我的红包</div>
    </div>
    <div class="gift_top">
      <img src="@/assets/member/hb.png" alt="" />
      <div class="hbze">
        <p>红包总额</p>
        <p>
          ¥<strong>{{ total }}</strong>
        </p>
      </div>
      <div class="hbgs">当前可用红包：{{ count }}个</div>
    </div>
    <ul class="clearfix syzt">
      <li :class="{ about: gift_zt == -1 }" @click="toClick(-1)">待使用</li>
      <li :class="{ about: gift_zt == 1 }" @click="toClick(1)">已使用</li>
      <li :class="{ about: gift_zt == 2 }" @click="toClick(2)">已过期</li>
    </ul>
    <div class="hbq">
      <!-- 未使用 -->
      <ul v-infinite-scroll style="overflow:auto">
        <li
          class="quan"
          v-for="item in list"
          :key="item.id"
          :class="item.status == 0 ? '' : 'act'"
          @click="toPath"
        >
          <div class="con_top">
            <i>¥</i><span>{{ item.money }}</span>
          </div>
          <div class="con_zj">
            <h5>{{ item.name }}</h5>
            <p>有效期：{{ item.end_time }}</p>
            <span>满{{ item.min_money }}元可用</span>
          </div>
          <div class="sy" v-if="item.status == 0">去使用</div>
          <div class="ysy" v-else-if="item.status == 1"></div>
          <div class="ygq" v-else-if="item.status == 2"></div>
          <div class="ction" v-if="item.status == 0">{{ item.desc }}</div>
        </li>
        
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 0,
      gift_zt: -1,
      page: 1,
      total: 0,
      count: 0,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toClick(index) {
      if (this.gift_zt == index) {
        return;
      }
      this.gift_zt = index;
      if (index == -1) {
        this.status = 0;
      } else {
        this.status = index;
      }
      this.getList();
    },
    getList() {
      let user_id = this.$store.state.user_id;
      this.$get("api/user/giftlist", {
        status: this.status,
        user_id,
        page: this.page,
      }).then((res) => {
        if (res.code == 0) {
          this.list = res.ret.list;
          if (this.gift_zt == -1 && res.ret.list) {
            this.total = res.ret.total;
            this.count = res.ret.count;
          }
        }
      });
    },
    toPath() {
      if (this.gift_zt == -1) {
        this.$router.push({
          path: "/lobby",
        });
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.giftbar {
  background-color: #fff;
  border-radius: 12px;
  .gift_top {
    display: flex;
    align-items: center;
    width: 894px;
    height: 140px;
    margin-left: 35px;
    background: url("../../assets/member/bj-wdhb.png") no-repeat center center;
    background-size: 100% 100%;
    img {
      margin-left: 18px;
      width: 66px;
      height: 66px;
    }
    .hbze {
      height: 53px;
      width: 180px;
      color: #fff;
      margin-left: 25px;
      p:nth-child(1) {
        font-size: 14px;
      }
      p:nth-child(2) {
        font-size: 14px;
        strong {
          font-size: 30px;
        }
      }
    }
    .hbgs {
      width: 180px;
      margin-left: 450px;
      margin-top: 50px;
      color: #fff;
      font-size: 14px;
    }
  }
  .syzt {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f6f7fa;
    li {
      float: left;
      margin-left: 56px;
      font-size: 14px;
      line-height: 50px;
       cursor: pointer;
    }
    .about {
      position: relative;
     
    }
    .about::before {
      position: absolute;
      left: 50%;
      bottom: 8px;
      transform: translate(-50%, 0);
      display: block;
      content: "";
      width: 15px;
      height: 3px;
      background: #3c7efe;
      border-radius: 1.5px;
    }
  }
  .hbq {
    margin: 20px 0 0 35px;
    ul {
      width: 894px;
      height: 564px;
      display: inline-block;
      li:nth-child(odd) {
        margin: 0 18px 12px 0;
      }
    }
  }
}
//共用样式
.btt {
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #f6f7fa;
  padding: 25px 0 0 35px;
  .btts {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    span {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #3c7efe;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
}

//红包
.quan {
  position: relative;
  float: left;
  width: 438px;
  height: 118px;
  background-color: #fef5f0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 20px;
}
.ction {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 94px;
  background-color: #ffe5e6;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 4px;
  font-size: 11px;
  text-align: center;
  line-height: 17px;
  color: #f13b26;
}
.sy {
  width: 68px;
  height: 32px;
  background: #fa4f3b;
  border-radius: 16px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.con_top i {
  font-style: normal;
  font-size: 16px;
  color: #f63822;
}
.con_top span {
  font-size: 48px;
  color: #f63822;
  font-weight: 700;
}
.con_zj {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
}
.con_zj h5 {
  font-size: 16px;
  color: #f73821;
  font-weight: 700;
}
.con_zj p {
  font-size: 12px;
  color: #fa503c;
}
.con_zj span {
  display: block;
  width: 79px;
  height: 19px;
  background: #ffdeb3;
  border-radius: 9.5px;
  font-size: 12px;
  text-align: center;
  line-height: 19px;
  color: #fa503c;
}
.act {
  background: #f6f7fa;
}
.act .con_top i {
  color: #848a9e;
}
.act .con_top span {
  color: #848a9e;
}
.act .con_zj h5 {
  color: #848a9e;
}
.act .con_zj p {
  color: #848a9e;
}
.act .con_zj span {
  color: #fff;
  background-color: #cfd4de;
}
.act .ysy {
  height: 100%;
  width: 78px;
  background: url(../../assets/member/ysy.png) no-repeat center center;
  background-size: 100% 100%;
}
.act .ygq {
  height: 100%;
  width: 78px;
  background: url(../../assets/member/ygq.png) no-repeat center center;
  background-size: 100% 100%;
}
</style>