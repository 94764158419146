module.exports = {
  domain: 'https://api.2zh.com/',
  //domain: 'https://www.tl.cn/',
  // domain: 'https://zhtest.youmaiyy.com/',

  key: '35e6157be71c0c4589072facdadf945d',
  ver: '1.0.0',
  pt: 4,
  appid: 0, 
  getCode: true,
  loginflg: true
}
